<template>
  <div>
    <basic ref="templateBasic" v-if="templateId===1" :modalData="modalData" :table="tableData" :night="nightService"  />
    <other ref="templateOther" v-if="templateId===2" :service="modalData" :night="nightService" />
  </div>
</template>
<script>
import basic from './basic.vue'
import other from './other.vue'
export default {
  components:{
    basic,
    other
  },
  props:{
    templateId:{
      type:Number,
      default: null,
    },
    modalData:{
      type: [Object,Array],
    },
    tableData:{
      type:Array,
      default: function(){
        return []
      }
    },
    nightService:{
      type: Object,
      default: function(){
        return {}
      }
    }
  },
  data(){
    return {

    }
  },
  methods:{
    getDate(){
      if(this.templateId===1){
        return this.$refs.templateBasic.getData()
      }else if(this.templateId===2){
        return this.$refs.templateOther.getData()
      }
    },
    getTableDate(){
      if(this.templateId===1){
        return this.$refs.templateBasic.getTableDate()
      }else if(this.templateId===2){
        return this.$refs.templateOther.getTableDate()
      }
    },
    getNightServe() {
      if(this.templateId===1){
        return this.$refs.templateBasic.getNightServe()
      }else if(this.templateId===2){
        return this.$refs.templateOther.getNightServe()
      }
    },
  }
}
</script>
<style scoped lang="scss">

</style>